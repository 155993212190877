import { useLazyQuery, useMutation } from "@apollo/client";
import { RouteComponentProps, navigate } from "@reach/router";
import { AccountSource } from "__generated__/GraphQLTypes";
import { message } from "antd";
import useOrganization from "app/hooks/useOrganization";
import { AUTH } from "graphql/auth/mutations";
import { GET_USER_ORGANIZATION } from "graphql/organizations/queries";
import React from "react";
import LoginForm from "../components/LoginForm";
import { StyledFormWrapper } from "../styles";
import { ILoginFormValues } from "../types";
import { authManager } from "../utils";

const Login: React.FC<RouteComponentProps> = (): JSX.Element => {
  const [auth, { loading }] = useMutation(AUTH);

  const [getOrganization, { data: organizationData }] = useLazyQuery(GET_USER_ORGANIZATION);
  const [, handleSelecting] = useOrganization();

  const handleLogin = async (formValues: ILoginFormValues) => {
    try {
      const result = await auth({
        variables: { input: { ...formValues, email: formValues?.email?.toLocaleLowerCase() } }
      });
      const user = result.data?.auth?.user;

      authManager.storeToken(result.data.auth);
      message.success("Connexion réussie");

      if (user?.account?.source === AccountSource.Staff) {
        navigate("/selectOrganization", { replace: true, state: user });
      } else {
        const organizationId = user?.organizationId;
        const res = await getOrganization({ variables: { id: organizationId } });
        handleSelecting(res?.data?.result);
        navigate("/campaigns", { replace: true, state: user });
      }
    } catch (error) {
      message.error("Une erreur s'est produite lors de la connexion");
    }
  };

  return (
    <StyledFormWrapper>
      {/* <LoginWithGoogleButton onClick={onGoogleLogin} /> */}
      {/* <Divider plain>ou</Divider> */}
      <div className="header-text">Connexion</div>
      <LoginForm submitted={loading} onLogin={handleLogin} />
    </StyledFormWrapper>
  );
};

export default Login;
